import python from "../Assets/python.png";
import java from "../Assets/java.png";
import c from "../Assets/c.png";
import javascript from "../Assets/javascript.png";
import typescript from "../Assets/typescript.png";
import react from "../Assets/react.png";
import angular from "../Assets/angular.png";
import express from "../Assets/express.png";
import nodejs from "../Assets/nodejs.png";
import mongodb from "../Assets/mongodb.png";
import mysql from "../Assets/mysql.png";
import firebase from "../Assets/firebase.png";
import nestjs from "../Assets/nestjs.png";
import springboot from "../Assets/springboot.png";
import babylonjs from "../Assets/babylonjs.svg";
import ionic from "../Assets/ionic.png";
import docker from "../Assets/docker.png";
import flask from "../Assets/flask.svg";
import materialui from "../Assets/materialui.svg";
import html from "../Assets/html.png";
import css from "../Assets/css.png";
import coordinates from "../Assets/coordinates.jpeg";
import hostelmanagement from "../Assets/hostelmanagement.jpeg";
import pizzamaker from "../Assets/pizzamaker.png";
import nilconnect from "../Assets/nilconnect.png";
import massmaildispenser from "../Assets/massmaildispenser.png";
import eva from "../Assets/eva.jpeg";
import vishal from "../Assets/vishal.jpg";

export const imagepaths = (imageName) => {
  switch (imageName) {
    case "Python":
      return python;
    case "Java":
      return java;
    case "C":
      return c;
    case "Javascript":
      return javascript;
    case "Typescript":
      return typescript;
    case "React":
      return react;
    case "Angular":
      return angular;
    case "Express":
      return express;
    case "Nodejs":
      return nodejs;
    case "Mongodb":
      return mongodb;
    case "Mysql":
      return mysql;
    case "Firebase":
      return firebase;
    case "Nestjs":
      return nestjs;
    case "Springboot":
      return springboot;
    case "Babylonjs":
      return babylonjs;
    case "Ionic":
      return ionic;
    case "Docker":
      return docker;
    case "Flask":
      return flask;
    case "Html":
      return html;
    case "Css":
      return css;
    case "Vishal":
      return vishal;
    case "MaterialUI":
      return materialui;
    case "Coordinates":
      return coordinates;
    case "Pizzamaker":
      return pizzamaker;
    case "Hostelmanagement":
      return hostelmanagement;
    case "Nilconnect":
      return nilconnect;
    case "Massmaildispenser":
      return massmaildispenser;
    case "Eva":
      return eva;
    default:
      return null;
  }
};
