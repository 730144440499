import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Footer } from "../footer/footer";
import Projects from "../projects/projects";

export const Work = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Retrieve the scroll position from sessionStorage
    const scrollPosition = sessionStorage.getItem("scrollPosition");
    if (scrollPosition) {
      // Scroll to the target div
      window.scrollTo({
        top: parseInt(scrollPosition, 10),
        behavior: "smooth",
      });

      // Clear the scroll position after use
      sessionStorage.removeItem("scrollPosition");
    }
  }, []);

  const goToHome = () => {
    sessionStorage.removeItem("scrollPosition");
    navigate("/");
  };

  return (
    <div className="flex flex-col w-full h-full px-4 py-10 font-rubik overflow-scroll no-scrollbar max-w-7xl mx-auto md:px-16 lg:px-32">
      <div className="flex flex-col w-full">
        <div
          className="text-blue text-lg sm:text-xl md:text-2xl font-medium cursor-pointer"
          onClick={goToHome}
        >
          Vishal
        </div>
      </div>

      {/* Work */}
      <div className="flex flex-col w-full h-full mt-10">
        <h1 className="w-full h-[10%] bg-gradient-to-tr from-peach to-peachLite bg-clip-text text-transparent text-4xl font-medium">
          Work
        </h1>

        <div className="flex flex-col leading-loose font-normal text-blue text-base sm:text-lg md:text-xl max-w-full md:leading-loose mt-6">
          <h3 className="font-semibold">
            Evobi Automations Pvt Ltd{" "}
            <a
              href="https://biboxlabs.com/"
              target="blank"
              className="underline underline-offset-1"
            >
              (Bibox Labs)
            </a>
            <span className="text-base font-normal"> | Bengaluru</span>
          </h3>
          <p>
            An edutech company dedicated to creating products and support
            software that make coding fun and engaging for kids.
          </p>

          <div className="pl-5 mt-4">
            <p className="font-medium">
              Full Stack Developer{" "}
              <span className="text-base font-normal">
                {" "}
                | Oct 2023 - Jun 2024
              </span>
            </p>
            <p>
              I solely developed the backend for Port11, a learning management
              system aimed at providing practical knowledge in embedded systems
              through in-house developed courses.
            </p>

            <ul className="list-disc pl-6 mt-2">
              <li>
                Created endpoints for the front-end application using NestJS and
                TypeScript with standard industry practices.
              </li>
              <li>
                Wrote business logic to handle operations, calculate scores, and
                store results in MongoDB.
              </li>
              <li>
                Implemented role-based authentication for endpoint access using
                CASL.js.
              </li>
              <li>
                Developed middlewares and aggregates using Mongoose to manage
                complexities when updating or retrieving documents.
              </li>
            </ul>

            <p className="font-medium mt-4">
              Web Application Developer{" "}
              <span className="text-base font-normal">
                {" "}
                | Jun 2022 - Sep 2023
              </span>
            </p>
            <p>
              I was responsible for developing new features for existing
              applications, providing post-production support, and creating
              proof of concepts (POCs) for new business requirements.
            </p>

            <ul className="list-disc pl-6 mt-2">
              <li>
                Independently developed the 'AppMode' feature with speech, face,
                and object recognition using Teachable Machine, TensorFlow.js,
                and react-speech recognition.
              </li>
              <li>
                Solely developed the ‘live code generation’ feature for Scratch
                MIT blocks, generating predefined C code when a block is added
                to the workspace.
              </li>
              <li>
                Developed a desktop app for compiling MicroPython code using a
                Python compiler and the adafruit-ampy library.
              </li>
              <li>
                Replicated an ESP flasher tool for EspressIf chips into a
                separate React app for user-friendly and seamless firmware
                updates.
              </li>
              <li>
                Migrated React Class components to Functional components,
                including code revamping, bug fixing, and debugging.
              </li>
              <li>Built a game engine (PlodeX) for kids using BabylonJS.</li>
            </ul>
          </div>

          <h4 className="font-semibold mt-8">
            All Blue Solutions{" "}
            <a
              href="https://subscribeit.com/"
              target="blank"
              className="underline underline-offset-1"
            >
              (Subscribe IT)
            </a>
            <span className="text-base font-normal"> | Remote</span>
          </h4>
          <p>
            SubscribeIT specializes in enterprise software solutions, offering
            expertise in software asset management, security, infrastructure,
            and cloud services for major platforms like Oracle, Microsoft, and
            IBM.
          </p>

          <div className="pl-5 mt-4">
            <p className="font-medium">
              Software Developer{" "}
              <span className="text-base font-normal">
                {" "}
                | Jul 2021 – Feb 2022
              </span>
            </p>

            <ul className="list-disc pl-6 mt-2">
              <li>
                Worked as a Front-End Developer with frameworks like Angular and
                Ionic.
              </li>
              <li>
                Involved in post-production development support, including UI
                changes, debugging, and bug fixing.
              </li>
              <li>
                Contributed to the development of an integrated chatbot
                application.
              </li>
              <li>
                Developed a mobile app for the chatbot application using Ionic.
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Projects */}
      <div id="targetDiv" className="flex flex-col w-full h-full mt-10">
        <h1 className="bg-gradient-to-tr from-peach to-peachLite bg-clip-text text-transparent text-4xl md:text-5xl font-medium">
          My Projects
        </h1>
        <Projects />
      </div>
      <div className="flex flex-row items-center justify-center w-full text-lg text-blue gap-4 mt-10">
        <Footer />
      </div>
    </div>
  );
};
