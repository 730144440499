import React, { useEffect, useRef, useState } from "react";
import "./landingPage.css";
import { imagepaths } from "../../utils/image";
import { useNavigate } from "react-router-dom";
import gsap from "gsap";
import { Footer } from "../footer/footer";

export const LandingPage = () => {
  const navigate = useNavigate();
  const centerSvgContainerRef = useRef(null);
  const titleRef = useRef(null);
  const subtitleRef1 = useRef(null);
  const subtitleRef2 = useRef(null);
  const circleRef = useRef(null);

  const [circleAnimationEnded, setCircleAnimationEnded] = useState(false);
  const [view, setView] = useState([
    220, 290, 390, 490, 590, 690, 790, 890, 990, 1090, 1190, 1290,
  ]);
  const [leftSideCircles, setLeftSideCircles] = useState([]);
  const [rightSideCircles, setRightSideCircles] = useState([]);

  const notMobileView = [
    220, 290, 390, 490, 590, 690, 790, 890, 990, 1090, 1190, 1290,
  ];
  const mobileView = [120, 190, 260, 320, 380, 440];

  const mobileLeftSideCircles = [
    { cx: 0, cy: 0, image: "Python", color: "#FFCA1B" },
    { cx: 180, cy: 950, image: "Python", color: "#FFCA1B" },
    { cx: 200, cy: 450, image: "C", color: "#3849ab" },
    { cx: 150, cy: 100, image: "Java", color: "#f8971c" }, //
    { cx: 380, cy: 820, image: "Javascript", color: "#EFDB4F" },
    { cx: 340, cy: 250, image: "Typescript", color: "#000000" },
    { cx: 200, cy: 650, image: "Express", color: "#000000" },
    { cx: 700, cy: 350, image: "React", color: "#63e0fa" },
    { cx: 700, cy: 140, image: "Angular", color: "#c3012f" },
  ];

  const mobileRightSideCircles = [
    { cx: 180, cy: 950, image: "Nodejs", color: "#7fbc02" },
    { cx: 200, cy: 450, image: "Ionic", color: "#4f8df7" },
    { cx: 150, cy: 100, image: "Docker", color: "#079cec" },
    { cx: 380, cy: 820, image: "Firebase", color: "#ffca26" }, //
    { cx: 340, cy: 250, image: "Nestjs", color: "#e0234e" },
    { cx: 200, cy: 650, image: "Springboot", color: "#8bc24a" },
    { cx: 700, cy: 350, image: "Mongodb", color: "#499d4a" },
    { cx: 700, cy: 140, image: "Mysql", color: "#ff9a68" },
  ];

  const notMobileLeftSideCircles = [
    { cx: 0, cy: 0, image: "Python", color: "#FFCA1B" },
    { cx: 50, cy: 750, image: "Python", color: "#FFCA1B" },
    { cx: 100, cy: 450, image: "C", color: "#3849ab" },
    { cx: 150, cy: 140, image: "Java", color: "#f8971c" }, //
    { cx: 325, cy: 850, image: "Javascript", color: "#EFDB4F" },
    { cx: 375, cy: 250, image: "Typescript", color: "#000000" },
    { cx: 300, cy: 550, image: "Express", color: "#000000" },
    { cx: 560, cy: 600, image: "React", color: "#63e0fa" },
    { cx: 700, cy: 240, image: "Angular", color: "#c3012f" },
  ];

  const notMobileRightSideCircles = [
    { cx: 50, cy: 750, image: "Nodejs", color: "#7fbc02" },
    { cx: 100, cy: 450, image: "Ionic", color: "#4f8df7" },
    { cx: 150, cy: 140, image: "Docker", color: "#079cec" },
    { cx: 325, cy: 850, image: "Firebase", color: "#ffca26" }, //
    { cx: 375, cy: 250, image: "Nestjs", color: "#e0234e" },
    { cx: 300, cy: 550, image: "Springboot", color: "#8bc24a" },
    { cx: 560, cy: 600, image: "Mongodb", color: "#499d4a" },
    { cx: 700, cy: 240, image: "Mysql", color: "#ff9a68" },
  ];

  useEffect(() => {
    const tl = gsap.timeline({ defaults: { ease: "power4.inOut" } });

    tl.fromTo(
      titleRef.current,
      { opacity: 0, scale: 0.8, rotation: -15 },
      { opacity: 1, scale: 1, rotation: 0, duration: 1.5 }
    ).fromTo(
      [subtitleRef1.current, subtitleRef2.current],
      { opacity: 0, y: 30 },
      { opacity: 1, y: 0, duration: 1 },
      "-=0.8" // Start this animation 0.8 seconds before the previous one ends
    );
  }, []);

  const [tooltip, setTooltip] = useState({
    visible: false,
    color: "#ffffff",
    index: -1,
    text: "",
  });

  const [centerDimensions, setCenterDimensions] = useState({
    width: 0,
    height: 0,
    top: 0,
    bottom: 0,
  });

  useEffect(() => {
    const handleAnimationEnd = () => {
      setCircleAnimationEnded(true);
    };

    const element = circleRef.current;

    if (element) {
      element.addEventListener("animationend", handleAnimationEnd);
    }

    return () => {
      if (element) {
        element.removeEventListener("animationend", handleAnimationEnd);
      }
    };
  }, []);

  useEffect(() => {
    if (centerDimensions.width < 768) {
      setView(mobileView);
      setLeftSideCircles(mobileLeftSideCircles);
      setRightSideCircles(mobileRightSideCircles);
    } else {
      setView(notMobileView);
      setLeftSideCircles(notMobileLeftSideCircles);
      setRightSideCircles(notMobileRightSideCircles);
    }
    // eslint-disable-next-line
  }, [centerDimensions]);

  useEffect(() => {
    // Get the bottomDimensions of the div
    const centerSvgContainer = centerSvgContainerRef.current;

    const center_div_details = centerSvgContainer.getBoundingClientRect();

    // Update the bottomDimensions state
    setCenterDimensions({
      width: center_div_details.width,
      height: center_div_details.height,
      top: center_div_details.top,
      bottom: center_div_details.bottom,
    });

    // Handle window resize
    const handleResize = () => {
      const ct_div_details = centerSvgContainer.getBoundingClientRect();

      setCenterDimensions({
        width: ct_div_details.width,
        height: ct_div_details.height,
        top: ct_div_details.top,
        bottom: ct_div_details.bottom,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMouseOver = (name, ind, col) => {
    setTooltip({
      visible: true,
      color: col,
      index: ind,
      text: name,
    });
  };

  const handleMouseMove = (event) => {
    setTooltip((prevTooltip) => ({
      ...prevTooltip,
    }));
  };

  const handleMouseOut = () => {
    setTooltip({ visible: false, text: "", index: null });
  };

  const navigateToAbout = () => {
    navigate("/about");
  };

  const navigateToWork = () => {
    navigate("/work");
  };

  const calculatePosition = (cx, cy, isRightSide) => {
    if (isRightSide) {
      return {
        cx: centerDimensions.width - (cx / 1920) * centerDimensions.width,
        cy: (cy / 1080) * centerDimensions.height,
      };
    }
    return {
      cx: (cx / 1920) * centerDimensions.width,
      cy: (cy / 1080) * centerDimensions.height,
    };
  };

  return (
    <div className="flex flex-col w-full h-screen">
      <div className="flex flex-col w-full h-4/5 bg-white">
        <div
          className="flex flex-col w-full h-full"
          ref={centerSvgContainerRef}
        >
          <svg
            // style={{ position: "relative", border: "1px solid red" }}
            width="100%"
            height="100%"
            viewBox={`0 0 ${centerDimensions.width} ${centerDimensions.height}`}
          >
            {view.map((radius, index) => {
              return (
                <React.Fragment key={index}>
                  {index === 0 ? (
                    <g key={index} className="relative z-20">
                      {/* Border effect */}
                      <circle
                        cx={centerDimensions.width / 2}
                        cy={(centerDimensions.height / 4) * 2.39}
                        r={radius + 4} // Increase radius to make room for the border
                        stroke={"#515070"} // Border color
                        strokeWidth={2} // Border thickness
                        fill="none"
                        className="shadow-2xl" // Apply shadow
                      />

                      {/* Main animated circle */}
                      <circle
                        ref={circleRef}
                        key={index}
                        cx={centerDimensions.width / 2}
                        cy={(centerDimensions.height / 4) * 2.39}
                        r={radius}
                        strokeDasharray={2 * radius * 3.141}
                        strokeDashoffset={2 * radius * 3.141}
                        className="animated-circle"
                        stroke={"#BCCBD8"}
                        strokeWidth={0.5}
                        fill="none"
                      />

                      {/* Clipping path for the image */}
                      <clipPath id={`circleClip${index}`}>
                        <circle
                          cx={centerDimensions.width / 2}
                          cy={(centerDimensions.height / 4) * 2.39}
                          r={radius}
                        />
                      </clipPath>

                      {/* Image inside the circle */}
                      <image
                        x={centerDimensions.width / 2 - 220}
                        y={(centerDimensions.height / 4) * 2.39 - 220}
                        width="440"
                        height="440"
                        href={imagepaths("Vishal")}
                        clipPath={`url(#circleClip${index})`}
                        preserveAspectRatio="xMidYMid slice"
                      />
                    </g>
                  ) : (
                    <circle
                      key={index}
                      cx={centerDimensions.width / 2}
                      cy={(centerDimensions.height / 4) * 2.39}
                      r={radius}
                      strokeDasharray={2 * radius * 3.141}
                      strokeDashoffset={2 * radius * 3.141}
                      className="animated-circle"
                      stroke={index % 2 === 0 ? "#515070" : "#FF8E6E"}
                      strokeWidth={2}
                      fill="none"
                      transform={`rotate(${index % 2 === 0 ? 90 : -90}, ${
                        centerDimensions.width / 2
                      }, ${(centerDimensions.height / 4) * 2.39}) ${
                        index % 2 === 0
                          ? ""
                          : `scale(-1, 1) translate(-${centerDimensions.width}, 0)`
                      }  `}
                    ></circle>
                  )}
                </React.Fragment>
              );
            })}
            {/* Header Curve*/}
            <svg
              width="100%"
              height="auto"
              viewBox={`0 0 ${centerDimensions.width} ${centerDimensions.height}`}
              preserveAspectRatio="xMidYMid meet"
            >
              <g>
                {/* Flattened curve for mobile */}
                <path
                  d={`M${centerDimensions.width / 4},0 Q${
                    centerDimensions.width / 2
                  },${
                    centerDimensions.width < 768
                      ? centerDimensions.height / 10 // Flatter curve for mobile
                      : centerDimensions.height / 5
                  } ${(centerDimensions.width / 4) * 3},0`}
                  stroke="#515070"
                  strokeWidth={2}
                  fill="white"
                />
                <defs>
                  <linearGradient
                    id="textGradient"
                    gradientTransform="rotate(90)"
                  >
                    <stop offset="0%" stopColor="#85859b" /> {/* slate-600 */}
                    <stop offset="50%" stopColor="#74738d" /> {/* gray-500 */}
                    <stop offset="100%" stopColor="#515070" /> {/* slate-400 */}
                  </linearGradient>
                </defs>
                {/* Responsive Text for "About" */}
                <text
                  className="font-rubik"
                  x={
                    centerDimensions.width < 768
                      ? centerDimensions.width / 2.7
                      : centerDimensions.width / 2.5
                  }
                  y={
                    centerDimensions.width < 768
                      ? centerDimensions.height / 35 // Flatter curve for mobile
                      : centerDimensions.height / 20
                  }
                  fontSize={centerDimensions.width < 768 ? "16" : "20"} // Adjust font size for mobile
                  cursor="pointer"
                  fill="url(#textGradient)"
                  onClick={navigateToAbout}
                >
                  About
                </text>
                {/* Responsive Text for "Work" */}
                <text
                  x={
                    centerDimensions.width -
                    60 -
                    centerDimensions.width /
                      (centerDimensions.width < 768 ? 3.5 : 2.5)
                  }
                  y={
                    centerDimensions.width < 768
                      ? centerDimensions.height / 35 // Flatter curve for mobile
                      : centerDimensions.height / 20
                  }
                  className="font-rubik"
                  fontSize={centerDimensions.width < 768 ? "16" : "20"} // Adjust font size for mobile
                  cursor="pointer"
                  fill="url(#textGradient)"
                  onClick={navigateToWork}
                >
                  Work
                </text>
              </g>
            </svg>

            {/* Footer Curve */}
            <path
              d={`M0,${centerDimensions.height} Q${
                centerDimensions.width / 2
              },${
                centerDimensions.width < 768
                  ? centerDimensions.height / 1.3 // Flatter curve for mobile
                  : centerDimensions.height / 1.7
              } ${centerDimensions.width},${centerDimensions.height}`}
              stroke="#515070"
              strokeWidth={2}
              fill="white"
            />
            <svg
              className={circleAnimationEnded ? "visible" : "hidden"}
              width="100%"
              height="100%"
              viewBox={`0 0 ${centerDimensions.width} ${centerDimensions.height}`}
              xmlns="http://www.w3.org/2000/svg"
            >
              {[...leftSideCircles, ...rightSideCircles].map((pos, index) => {
                const isRightSide = index >= leftSideCircles.length;
                const color = pos.color;
                const image = pos.image;
                const id = index;
                const position = calculatePosition(pos.cx, pos.cy, isRightSide);
                return (
                  <g key={index} className={id === 0 ? "hidden" : "revolve"}>
                    <circle
                      cx={position.cx}
                      cy={position.cy}
                      r={Math.round(centerDimensions.height / 20 / 10) * 10}
                      stroke="#85859b"
                      fill="#F2F2F2"
                      strokeWidth={0.5}
                      clipPath={`url(#circleClip${id})`}
                      tooltip={image}
                    />
                    <clipPath id={`circleClip${id}`}>
                      <circle
                        cx={position.cx}
                        cy={position.cy}
                        r={Math.round(centerDimensions.height / 20 / 10) * 10}
                      />
                    </clipPath>
                    <image
                      x={
                        position.cx -
                        Math.round(centerDimensions.height / 20 / 10) * 10
                      }
                      y={
                        position.cy -
                        Math.round(centerDimensions.height / 20 / 10) * 10
                      }
                      width={
                        Math.round(centerDimensions.height / 20 / 10) * 10 * 2 // Diameter of the circle
                      }
                      height={
                        Math.round(centerDimensions.height / 20 / 10) * 10 * 2 // Diameter of the circle
                      }
                      href={imagepaths(image)}
                      clipPath={`url(#circleClip${id})`}
                      preserveAspectRatio="xMidYMid slice"
                      cursor="pointer"
                      onMouseOver={(e) => handleMouseOver(image, id, color)}
                      onMouseMove={handleMouseMove}
                      onMouseOut={handleMouseOut}
                    />
                    <defs>
                      <linearGradient
                        id="toolTipGradient"
                        gradientTransform="rotate(90)"
                      >
                        <stop offset="0%" stopColor={tooltip.color} />{" "}
                        {/* Tailwind color: slate-600 */}
                        <stop offset="100%" stopColor="#475569" />{" "}
                        {/* Tailwind color: slate-400 */}
                      </linearGradient>
                    </defs>
                    {tooltip.visible && tooltip.index === id && (
                      <text
                        fontFamily="Rubik"
                        textAnchor="middle"
                        x={position.cx}
                        y={position.cy + 60}
                        fontSize="20"
                        fill="url(#toolTipGradient)"
                      >
                        {tooltip.text}
                      </text>
                    )}
                  </g>
                );
              })}
            </svg>
          </svg>
        </div>
      </div>
      <div className="flex flex-col w-full h-1/5 justify-center items-center max-w-7xl mx-auto">
        <h1
          ref={titleRef}
          className="text-peach text-3xl sm:text-3xl md:text-5xl lg:text-6xl font-rubik text-center"
        >
          Vishal Meda Srinivasa Murthy
        </h1>
        <p
          ref={subtitleRef1}
          className="text-blue text-sm sm:text-base md:text-lg lg:text-xl font-rubik text-center"
        >
          I am a Fullstack Developer with 3 yrs of experience in MERN.
        </p>
        <div
          ref={subtitleRef2}
          className="flex flex-row items-end justify-center text-base sm:text-lg text-blue gap-4 w-full h-screen"
        >
          <Footer />
        </div>
        <br></br>
      </div>
    </div>
  );
};
