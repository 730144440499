export const projects = [
  {
    id: "1",
    title: "EVA (Email Voice Assistant)",
    description: "Voice-controlled email management for visually impaired.",
    technologies: ["Javascript", "Html", "Css"],
    githubLink: "Eva",
    image: "Eva",
    live: false,
    demoLink: "https://demo.example.com",
    sourceLink: "https://github.com/msvish/EVA",
    features: [
      "Web-based Chrome extension",
      "Designed for visually impaired users",
      "Utilizes Interactive Voice Response (IVR)",
      "Voice-controlled mail account management",
      "Supports reading, sending, and other mail operations",
      "Voice prompts for user actions",
      "User responds to voice commands",
    ],
  },
  {
    id: "2",
    title: "Pizza Maker",
    description: "Customizable pizza app with drag-and-drop.",
    technologies: ["Javascript", "React", "MaterialUI"],
    githubLink: "PizzaMaker",
    image: "Pizzamaker",
    live: true,
    demoLink: "https://sage-squirrel-e59697.netlify.app/",
    sourceLink: "https://github.com/msvish/PizzaMaker",
    features: [
      "Horizontal scrolling",
      "Drag and drop",
      "Image overlapping",
      "Material design react components",
    ],
  },
  {
    id: "3",
    title: "Hostel Management",
    description: "Streamlined student and hostel management system.",
    technologies: ["Java", "Mysql", "Html", "Css"],
    githubLink: "HostelManagement",
    image: "Hostelmanagement",
    live: false,
    demoLink: "https://demo.example.com",
    sourceLink: "https://github.com/msvish/HostelManagement",
    features: [
      "UI Interface for Signing Up and managing students",
      "Implementation to connect Frontend to MySQL using MySQL connector ",
      "Trigger Implementation",
      "Aggregator Implementation",
    ],
  },
  {
    id: "4",
    title: "Co-Ordinates Finder",
    description: "Get coordinates for any global address.",
    technologies: ["Python", "Flask"],
    githubLink: "Coordinates",
    image: "Coordinates",
    live: false,
    demoLink: "https://demo.example.com",
    sourceLink: "https://github.com/msvish/CoOrdinatesFinder",
    features: ["API Development", "Google Maps Geocoding API Integration"],
  },
  {
    id: "5",
    title: "Nilconnect",
    description: "A video conferencing platform",
    technologies: [
      "Javascript",
      "React",
      "Html",
      "Css",
      "MaterialUI",
      "Jitsi",
      "Firebase",
    ],
    githubLink: "https://github.com/msvish/Nilconnect",
    image: "Nilconnect",
    live: false,
    demoLink: "https://demo.example.com",
    sourceLink: "https://github.com/msvish/Nilconnect",
    features: [
      "Google SSO Implementation",
      "Jitsi Meet Integration",
      "Real-Time User Chat",
    ],
  },
  {
    id: "6",
    title: "Mass Mail Dispenser",
    description: "Mass mail via CSV with invalid email sorting.",
    technologies: ["Html", "Python", "Flask", "Smtplib"],
    githubLink: "Coordinates",
    image: "Massmaildispenser",
    live: false,
    demoLink: "https://demo.example.com",
    sourceLink: "https://github.com/msvish/MassMailDispenser",
    features: [
      "Basic interface for entering information",
      "Validates email addresses",
      "Allows CSV file upload",
      "Displays invalid email addresses",
      "Api Integration",
    ],
  },
];
