import React from "react";
import { useNavigate } from "react-router-dom";
import { Footer } from "../footer/footer";

export const About = () => {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate("/");
  };

  return (
    <div className="flex flex-col w-full h-full lg:h-screen px-6 sm:px-12 md:px-24 lg:px-32 py-10 font-rubik max-w-7xl mx-auto">
      <div className="flex flex-col w-full mb-10">
        <div
          className="text-blue text-lg sm:text-xl md:text-2xl font-medium cursor-pointer"
          onClick={goToHome}
        >
          Vishal
        </div>
      </div>

      <div className="flex flex-col w-full mb-10">
        <div className="w-full h-[10%] bg-gradient-to-tr from-peach to-peachLite bg-clip-text text-transparent text-2xl sm:text-3xl md:text-4xl font-medium">
          About Me
        </div>
        <div className="flex flex-col font-normal text-blue text-base leading-loose sm:text-lg md:text-xl max-w-3xl sm:max-w-4xl md:max-w-5xl md:leading-loose">
          <p className="mb-6">Hola!</p>
          <p className="mb-6">
            I am Vishal. I enjoy challenging myself to build technologies beyond
            my knowledge and experience. I hold a bachelor’s degree in computer
            science from the Kammavari Sangha Institute of Technology
            <a
              href="https://www.ksit.ac.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="underline underline-offset-1"
            >
              (KSIT)
            </a>
            . At present, I am pursuing my master's in computer and information
            science at{" "}
            <a
              href="https://umdearborn.edu/"
              target="_blank"
              rel="noopener noreferrer"
              className="underline underline-offset-1"
            >
              University of Michigan - Dearborn
            </a>
            . I enjoy tackling challenges on platforms like{" "}
            <a
              href="https://www.hackerrank.com/profile/vishms1999"
              target="_blank"
              rel="noopener noreferrer"
              className="underline underline-offset-1"
            >
              HackerRank
            </a>
            .
          </p>
          <p>
            Apart from the technical stuff, I am fascinated by space
            exploration, the presence of UFOs (Unidentified Flying Objects), and
            the existence of extraterrestrial life. It's thrilling to think that
            there exist at least 1 trillion earth-like exoplanets in the
            universe, making the probability of alien life{" "}
            <a
              href="https://www.foxnews.com/politics/nasa-administrator-says-least-trillion-planets-like-earth-could-exist-universe"
              target="_blank"
              rel="noopener noreferrer"
              className="underline underline-offset-1"
            >
              1 trillion to 1
            </a>
            . Besides this, I love birdwatching, trekking, and playing
            badminton.
          </p>
        </div>
      </div>
      <div className="flex flex-row items-end justify-center text-base sm:text-lg text-blue gap-4 w-full h-full">
        <Footer />
      </div>
    </div>
  );
};
