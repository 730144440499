import React from "react";
import { SiPeerlist } from "react-icons/si"; // <SiPeerlist />
import { FaLinkedinIn } from "react-icons/fa6"; //<FaLinkedinIn />
import { SiGmail } from "react-icons/si"; //<SiGmail />
import { TbBrandGithubFilled } from "react-icons/tb"; //<TbBrandGithubFilled />

export const Footer = () => {
  return (
    <>
      <a
        href="https://github.com/msvish"
        target="_blank"
        rel="noreferrer"
        className="text-blue hover:underline text-2xl"
      >
        <TbBrandGithubFilled />
      </a>
      <a
        href="mailto:vishms1999@gmail.com"
        target="_blank"
        rel="noreferrer"
        className="text-blue hover:underline text-2xl"
      >
        <SiGmail />
      </a>
      <a
        href="https://www.linkedin.com/in/vishal-m-s-71373a147"
        target="_blank"
        rel="noreferrer"
        className="text-blue hover:underline text-2xl"
      >
        <FaLinkedinIn />
      </a>
      <a
        href="https://peerlist.io/vishal_ms"
        target="_blank"
        rel="noreferrer"
        className="text-blue hover:underline text-2xl"
      >
        <SiPeerlist />
      </a>
    </>
  );
};
