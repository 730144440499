import React from "react";

export const Button = ({
  variant = "primary",
  children,
  className = "",
  ...props
}) => {
  const baseStyles = "px-4 py-2 rounded font-semibold transition-colors";
  const variantStyles = {
    primary: "bg-blue text-white",
    outline: "border border-blue text-blue",
    contained: "bg-peach text-white",
  };

  return (
    <button
      className={`${baseStyles} ${variantStyles[variant]} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};
