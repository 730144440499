import React from "react";

export const Badge = ({ variant = "primary", children }) => {
  const baseStyles = "px-2 py-1 text-xs font-semibold rounded-full";
  const variantStyles = {
    secondary: "bg-gray-100 text-blue",
  };

  return (
    <span className={`${baseStyles} ${variantStyles[variant]}`}>
      {children}
    </span>
  );
};
