"use client";

import { useState, useEffect, useRef } from "react";
import { ExternalLink, Github } from "lucide-react";
import { Button } from "../../utils/button";
import { Badge } from "../../utils/badge";
import { motion, AnimatePresence } from "framer-motion";
import { imagepaths } from "../../utils/image";
import { projects } from "../../utils/projectInfo";

export default function Projects() {
  const [expandedProject, setExpandedProject] = useState(null);
  const projectRefs = useRef([]);

  const toggleProject = (projectId) => {
    setExpandedProject(expandedProject === projectId ? null : projectId);
  };

  const handleKeyDown = (e, projectId) => {
    if (e.key === "Enter" || e.key === " ") {
      e.preventDefault();
      toggleProject(projectId);
    }
  };

  useEffect(() => {
    if (expandedProject !== null) {
      projectRefs.current[expandedProject]?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [expandedProject]);

  return (
    <div className="container font-rubik mx-auto p-4 max-w-7xl mt-6">
      <div className="grid gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {projects.map((project, index) => (
          <motion.div
            key={project.id}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
            whileHover={{ scale: 1.03 }}
            className="border rounded-lg overflow-hidden shadow-lg bg-white"
            ref={(el) => (projectRefs.current[project.id] = el)}
          >
            <motion.div
              className="relative cursor-pointer"
              onClick={() => toggleProject(project.id)}
              onKeyDown={(e) => handleKeyDown(e, project.id)}
              tabIndex={0}
              role="button"
              aria-expanded={expandedProject === project.id}
              whileHover="hover"
            >
              <motion.img
                src={imagepaths(project.image)}
                alt={project.title}
                className="w-full h-48 object-cover"
                whileHover={{ scale: 1.1 }}
                transition={{ duration: 0.3 }}
              />
              <motion.div
                className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center"
                initial={{ opacity: 0 }}
                whileHover={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
              >
                <span className="text-white text-lg font-semibold">
                  {expandedProject === project.id
                    ? "Hide Details"
                    : "View Details"}
                </span>
              </motion.div>
            </motion.div>
            <div className="p-4">
              <h2 className="bg-gradient-to-tr from-peach to-peachLite bg-clip-text text-transparent text-xl font-semibold mb-2">
                {project.title}
              </h2>
              <p className="text-blue mb-4 line-clamp-2">
                {project.description}
              </p>
              <div className="flex flex-wrap gap-2 mb-4">
                {project.technologies.map((tech) => (
                  <Badge key={tech} variant="secondary">
                    {tech}
                  </Badge>
                ))}
              </div>
            </div>
            <AnimatePresence>
              {expandedProject === project.id && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.3 }}
                  className="p-4 bg-gray-50"
                >
                  <h3 className="bg-gradient-to-tr from-peach to-peachLite bg-clip-text text-transparent text-lg font-semibold mb-2">
                    Key Features
                  </h3>
                  <ul className="list-disc list-inside mb-4">
                    {project.features.map((feature, index) => (
                      <motion.li
                        key={index}
                        className="text-blue"
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.3, delay: index * 0.1 }}
                      >
                        {feature}
                      </motion.li>
                    ))}
                  </ul>
                  <div className="flex flex-wrap gap-4">
                    {project.live && (
                      <Button variant="outline" asChild>
                        <motion.a
                          href={project.demoLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center"
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                        >
                          <ExternalLink className="w-4 h-4 mr-2" />
                          Live Demo
                        </motion.a>
                      </Button>
                    )}
                    <Button variant="contained" asChild>
                      <motion.a
                        href={project.sourceLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        <Github className="w-4 h-4 mr-2" />
                        View Code
                      </motion.a>
                    </Button>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        ))}
      </div>
    </div>
  );
}
