import { Route, Routes } from "react-router-dom";
import { LandingPage } from "./components/LandingPage/landingPage";
import { About } from "./components/about/about";
import { Work } from "./components/work/work";
import Projects from "./components/projects/projects";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/about" element={<About />} />
        <Route path="/work" element={<Work />} />
        <Route
          path="/work/projects/Eva"
          element={<Projects title="EVA (Email Voice Assistant)" />}
        />
      </Routes>
    </div>
  );
}

export default App;
